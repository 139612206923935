<template>
  <div>
    <div class="flex justify-between py-2">
      <a-dropdown-button @click="open_add">
        {{ $t('add') }}
      </a-dropdown-button>
    </div>
    <custom-tabular
        ref="refCustomTabular"
        :column_defs="column_defs_create_org"
        :end_point="'organizations/pages_organization_request_list/'"
        @open_drawer="open_drawer"/>
  </div>
</template>

<script>
import {EventBus} from "@/configs/eventBus";
import CustomTabular from "@/components/CustomTabular/CustomTabular.vue";

export default {
  name: "Deal",
  components: {CustomTabular},
  methods:{
    open_add(){
      EventBus.$emit('open_create_org_deal');
    },
  },
  data(){
    return {
      column_defs_create_org:[
        {
          title: this.$t('name'),
          field: 'name',
          sortable: true,
          filter: true,
          width: 200,
          use: true,
        },

        {
          title: this.$t('bin'),
          field: 'bin',
          sortable: true,
          filter: true,
          width: 200,
          use: true,
        },

      ],
    }
  },
}
</script>

<style scoped lang="scss">

</style>